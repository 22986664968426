<template>
  <div class="header" id="main">
    <div class="left">
      <img
        class="logo"
        :src="
          myBusiness && myBusiness.logo != null ? myBusiness.logo : defaulHeader
        "
        alt=""
      />
      <span class="logo_title">{{
        myBusiness && myBusiness.name != "" ? myBusiness.name : ""
      }}</span>
      <!-- <span class="logo_title">北京小兰老师科技有限公司发发就就就就</span> -->
    </div>
    <div class="middle">
      <router-link
        to="/"
        style="wdith: 30px; margin-left: 30px"
        class="home"
        :style="{
          color: $route.path == '/' ? '#0C8DE3' : '#000',
        }"
        >首页</router-link
      >
      <router-link
        to="/standard"
        style="margin-left: 50px"
        class="standard"
        :style="{
          color: $route.path == '/standard' ? '#0C8DE3' : '#000',
        }"
        >标准规范查询</router-link
      >
      <router-link
        to="/reviewTitle"
        style="margin-left: 40px"
        class="standard"
        :style="{
          color: $route.path == '/reviewTitle' ? '#0C8DE3' : '#000',
        }"
        >职称评审</router-link
      >
      <!-- <router-link
        to="/courlist"
        class="exercise"
        style="margin-left: -10px"
        :style="{
          color: $route.query.tn
            ? '#000'
            : $route.path == '/courlist'
            ? '#0C8DE3'
            : '#000',
        }"
        >考试与练习</router-link
      > -->
      <!-- 整个右侧搜索 -->
      <!-- <div class="search">
        <el-select
          v-model="search_menu"
          class="search_menu"
          @change="chengeSearchFlag"
        >
          <el-option
            v-for="item in options"
            :key="item.search_menu"
            :label="item.label"
            :value="item.search_menu"
          >
          </el-option>
        </el-select>
        <el-input
          class="search_in"
          v-model="searchVal"
          :placeholder="`请输入${search_menu == 1 ? '课程' : '标准规范'}名称`"
          @keyup.enter.native="globalSearch"
        >
        </el-input>
        <div class="search_but">
          <p class="search_but_text" @click="globalSearch">搜索</p>
          <div class="right" v-if="$route.path !== '/'">
            <span
              class="please"
              style="display: inline-block; display: flex"
              v-if="!userInfo || JSON.stringify(userInfo) == '{}'"
              @click="loginMask"
            >
              <img
                style="width: 40px; height: 40px"
                :src="defaulHeader"
                alt=""
              />
              <p style="line-height: 10px; margin-left: 7px">请登录</p>
            </span>

            <el-dropdown trigger="click" style="cursor: pointer" v-else>
              <span class="el-dropdown-link" style="display: flex">
                <img
                  style="
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-top: 3px;
                  "
                  :src="
                    userInfo && userInfo.head_img !== null
                      ? userInfo.head_img
                      : defaulHeader
                  "
                  alt=""
                />
                <p class="header_name">
                  {{ userInfo.name }}
                  <i
                    class="el-icon-arrow-down el-icon--right"
                    style="margin-top: 3%; margin-left: 55%"
                  ></i>
                </p>
              </span>
              <div class="mymenu">
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="updateInfo"
                    >个人中心</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="logOut"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </div>
            </el-dropdown>
          </div>
        </div>
      </div> -->

      <div class="search">
        <div class="search_but">
          <!-- <p class="search_but_text" @click="globalSearch">搜索</p> -->
          <div class="right" v-if="$route.path !== '/'">
            <span
              class="please"
              style="display: inline-block; display: flex"
              v-if="!userInfo || JSON.stringify(userInfo) == '{}'"
              @click="loginMask"
            >
              <img
                style="width: 40px; height: 40px"
                :src="defaulHeader"
                alt=""
              />
              <p style="line-height: 10px; margin-left: 7px">请登录</p>
            </span>

            <el-dropdown trigger="click" style="cursor: pointer" v-else>
              <span class="el-dropdown-link" style="display: flex">
                <img
                  style="
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-top: 3px;
                  "
                  :src="
                    userInfo && userInfo.head_img !== null
                      ? userInfo.head_img
                      : defaulHeader
                  "
                  alt=""
                />
                <p class="header_name">
                  {{ userInfo.name }}
                  <i
                    class="el-icon-arrow-down el-icon--right"
                    style="margin-top: 3%; margin-left: 55%"
                  ></i>
                </p>
              </span>
              <div class="mymenu">
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="updateInfo"
                    >个人中心</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="logOut"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </div>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="c-b">
      <div class="certificate" ref="certificate">
        <span class="cert-name">{{ certInfo.name }}</span>
        <span class="cert-stime">{{ certInfo.start_time }}</span>
        <span class="cert-etime">{{ certInfo.end_time }}</span>
        <span class="cert-course-time">{{ certInfo.class_num }}</span>
        <span class="cert-identifier">{{ certInfo.cert_num }}</span>
        <span class="cert-ftime">{{ certInfo.end_time }}</span>
        <span class="cert-cname">{{ certInfo.cert_name }}</span>
      </div>
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div class="mask" v-if="maskShow">
        <div class="login-mask" v-if="loginShow" key="login">
          <i class="el-icon-close" @click="closeLoginMask('loginForm')"></i>
          <span>登录</span>
          <el-form
            ref="loginForm"
            label-width="auto"
            :rules="loginRule"
            :model="loginForm"
          >
            <el-form-item prop="mobile">
              <p slot="label" style="margin: 0"></p>
              <el-input
                placeholder="请输入手机号"
                prefix-icon="el-icon-mobile-phone"
                v-model="loginForm.mobile"
                type="number"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <p slot="label" style="margin: 0"></p>
              <el-input
                type="password"
                placeholder="请输入密码"
                prefix-icon="el-icon-lock"
                maxlength="16"
                @input="
                  loginForm.password = loginForm.password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
                v-model="loginForm.password"
                :onkeyup="(loginForm.password = loginForm.password.trim())"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click.prevent="loginMethods('loginForm')"
                >登录</el-button
              >
              <el-button @click="closeLoginMask('loginForm')">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import "animate.css";
import html2canvas from "html2canvas";

import defaulHeader from "../assets/images/default_header.png";
export default {
  data() {
    return {
      options: [
        {
          search_menu: "1",
          label: "课程",
        },
        {
          search_menu: "2",
          label: "标准规范",
        },
      ],
      search_menu: "1",
      searchVal: "",
      myHead: {},
      myBusiness: {},
      personImgUrl: "",
      defaulHeader: defaulHeader, //默认显示头像路径
      typeName: "", //
      loginForm: {
        mobile: "",
        password: "",
      }, //登陆表单
      loginRule: {
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
        password: { required: true, message: "请输入密码", trigger: "blur" },
      }, //登陆表单验证
      maskShow: false,
      loginShow: false,
      certInfo: {},
    };
  },
  mounted() {
    this.$store.dispatch("GET_GLOBAL_LOGIN", this.loginMask);
    this.getheadData();
    document.title = this.business ? this.business.name : "";

    console.log(window.location.href.includes("courplay"));
    // if (!window.location.href.includes("courplay")) {
    //   //如果当前页面不是courplay,就让下边自适应生效
    //   function bodyScale() {
    //     var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
    //     var scale = devicewidth / 1920; // 分母——设计稿的尺寸
    //     document.body.style.zoom = scale; //放大缩小相应倍数
    //   }
    //   bodyScale();
    // }else{
    //  let main = document.getElementById('main');
    //   console.log(main);
    //   main.style.width="100%"
    // }
    function bodyScale() {
      var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      var scale = devicewidth / 1920; // 分母——设计稿的尺寸
      // document.body.style.zoom = scale; //放大缩小相应倍数
    }
    bodyScale();
  },
  methods: {
    updateInfo() {
      this.$router.push("/personal");
    },
    logOut() {
      //this.popShow=true;
      this.axios.post("/login/login_out").then((res) => {
        // this.$router.go(0);
        this.$router.push("/");
        localStorage.removeItem("eft");
        // localStorage.removeItem("employee_info");
        this.$store.dispatch("SET_EMPLOYEE_INFO", JSON.stringify({}));
        // localStorage.removeItem("business_info");
      });
      // //this.popShow=true;
      // this.axios.post("/login/login_out").then((res) => {
      //   if (res.data.code == 0) {
      //     localStorage.removeItem("eft");
      //     localStorage.removeItem("employee_info");
      //     // this.$store.dispatch("SET_EMPLOYEE_INFO");
      //     // localStorage.removeItem("business_info");
      //     this.$router.push("/");
      //     this.$router.go(0);
      //   }
      // });
    },
    chengeSearchFlag(currentVal) {
      this.search_menu = currentVal;
    },
    globalSearch() {
      if (this.searchVal === "") {
        this.$message.error("请输入搜索内容!");
        return;
      }
      if (this.search_menu == 1) {
        this.$router.push(
          `/search?type=${this.search_menu}&search=${this.searchVal}`
        );
        if (this.$route.name === "Search") {
          this.$router.go(0);
        }
      }
      if (this.search_menu == 2) {
        this.$router.push(
          `/standard?type=${this.search_menu}&search=${this.searchVal}`
        );
        if (this.$route.name == "Standard") {
          this.$router.go(0);
        }
      }
      this.searchVal = "";
    },
    getheadData() {
      this.axios.post("/").then((res) => {
        this.myHead = res.data;
        this.myBusiness = res.data.business;
        this.$store.dispatch(
          "SET_EMPLOYEE_INFO",
          JSON.stringify(res.data.user_info)
        );
        if (res.data.is_has_cert == 0) {
          this.autoCert();
        }
      });
    },
    //  获取个人设置请求
    getPersonalSetData() {
      this.axios.post("index/my_settings").then((res) => {
        if (res.code == 0) {
          this.getPersonalSetData();
          this.getheadData();
        }
        console.log(res);
        this.realname = res.data.name;
        this.personImgUrl = res.data.head_img;
      });
    },
    // 登陆弹窗
    loginMask() {
      this.maskShow = true;
      this.loginShow = true;
    },
    closeLoginMask(formName) {
      this.maskShow = false;
      this.loginShow = false;
      this.$refs[formName].resetFields();
    },
    loginMethods(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          let { mobile, password } = this.loginForm;
          fd.append("mobile", mobile);
          fd.append("password", password);
          this.axios.post("/login/login", fd).then((res) => {
            if (res.data.code === 0) {
              localStorage.setItem("eft", res.data.data.token);
              this.$message.success(res.data.msg);
              this.getheadData();
              this.getIndexData();
              // this.$router.push("/");
              this.$router.go(0);
              setTimeout(() => {
                this.maskShow = false;
                this.loginShow = false;
                this.$refs[formName].resetFields(); //清空表单
              }, 800);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    getIndexData() {
      this.axios.get("/").then((res) => {
        console.log(1);
        if (
          res.data.user_info &&
          typeof this.$store.state.empInfo !== "Object"
        ) {
          this.$store.dispatch(
            "SET_EMPLOYEE_INFO",
            JSON.stringify(res.data.user_info)
          );
        }
        if (JSON.stringify(this.$store.state.business) === "{}") {
          this.$store.dispatch(
            "SET_BUSINESS_INFO",
            JSON.stringify(res.data.business)
          );
        }
      });
    },
    autoCert() {
      this.axios.post("/exam/automatic_cert").then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          return;
        } else {
          this.certInfo = res.data.info;
          setTimeout(() => {
            this.createCert();
          }, 800);
        }
      });
    },
    createCert() {
      html2canvas(this.$refs.certificate).then((canvas) => {
        let base64 = canvas.toDataURL("image/png");
        let fd = new FormData();
        fd.append("pic", base64);
        this.axios.post("/exam/cert_pic", fd).then((res) => {});
      });
    },
  },
  computed: {
    userInfo() {
      if (JSON.stringify(this.$store.state.empInfo) !== "{}") {
        return JSON.parse(this.$store.state.empInfo);
      }
    },
    business() {
      if (JSON.stringify(this.$store.state.business) !== "{}") {
        return JSON.parse(this.$store.state.business);
      }
    },
  },
};
</script>
<style lang="scss">
:root {
  --animate-duration: 0.3s;
}
.search {
  .el-input,
  .search_in .el-input__inner {
    border-color: #dcdfe6 !important;
    border-radius: 0%;
    height: 40px;
  }
  .search_menu {
    .el-input .el-input__inner {
      border-color: #dcdfe6 !important;
      text-align: center;
      border-radius: 0;
      border-right: 0px;
    }
  }
}

@media screen and (max-width: 1378px) {
  .el-dropdown-menu {
    position: absolute;
    left: 1450px !important;
    top: 200px;
    // background: pink;
  }
}
@media screen and (max-width: 1555px) and (min-width: 1380px) {
  .el-dropdown-menu {
    position: absolute;
    left: 1530px !important;
    top: 200px;
    // background: green;
  }
}
</style>
<style lang="scss" scoped>
// header中去除eslint下划线
a {
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
//150%
@media screen and (max-width: 1378px) {
  .header {
    width: 100%;
    height: 80px;
    background: #ffff;

    .left {
      width: 24%;
      height: 52px;
      display: flex;
      margin-left: 13%;
      .logo {
        // width: 60px;
        height: 50px;
        margin-top: 15px;
      }
      .logo_title {
        word-break: keep-all; /* 不换行 */
        white-space: nowrap; /* 不换行 */
        overflow: hidden;
        text-overflow: ellipsis;
        width: 300px;
        height: 100%;
        line-height: 52px;
        text-align: left;
        padding-left: 5px;
        margin-top: 14px;
        font-size: 18px;
        font-weight: bold;
        margin-left: 3%;
        //background: lemonchiffon;
        //display: none;
      }
    }

    .middle {
      width: 675px;
      height: 35px;
      font-size: 16px;
      font-weight: 400;
      margin-left: 38%;
      margin-top: -30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      // background: #8bb9ff;
      .search {
        // width: 364px;
        width: 230px;
        height: 35px;
        display: flex;
        margin-left: -10px;

        .search_menu {
          width: 235px; //128
          height: 20px;
          padding: auto;
        }
        .search_but {
          width: 130px;
          height: 40px;
          font-size: 16px;
          color: #ffffff;
          //background: #8bb9ff;
          .right {
            width: 120px;
            height: 45px;
            position: absolute;
            left: 106%;
            top: -4px;
            display: flex;
            padding-top: -100px;
            color: #000;
            .please {
              margin-top: 12px;
              margin-left: 10px;
              cursor: pointer;
            }
            & > img {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              object-fit: cover;
            }
            .header_name {
              //width: 100%;
              margin-left: 10px;
            }
            .el-icon--right {
              position: absolute;
              left: 50%;
              top: 187x;
            }
          }

          .search_but_text {
            margin-top: 8px;
            margin-left: 17px;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
// 125%
@media screen and (max-width: 1555px) and (min-width: 1380px) {
  .header {
    width: 100%;
    height: 80px;
    background: #ffff;
    .left {
      width: 450px;
      height: 52px;
      display: flex;
      margin-left: 13%;

      .logo {
        // width: 60px;
        height: 50px;
        margin-top: 15px;
      }
      .logo_title {
        word-break: keep-all; /* 不换行 */
        white-space: nowrap; /* 不换行 */
        overflow: hidden;
        text-overflow: ellipsis;
        width: 300px;
        height: 100%;
        line-height: 52px;
        text-align: left;
        margin-top: 14px;
        font-size: 18px;
        font-weight: bold;
        margin-left: 3%;
        padding-left: 10px;
        //background: olivedrab;
      }
    }
    .middle {
      width: 780px;
      height: 35px;
      font-size: 16px;
      font-weight: 400;
      margin-left: 36%;
      margin-top: -30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      //background: #8bb9ff;
      .search {
        // width: 364px;
        width: 200px;
        height: 35px;
        display: flex;
        margin-left: 45px;

        .search_menu {
          width: 235px; //128
          height: 20px;
          padding: auto;
          text-align: center;
          margin-left: -20%;
        }
        .search_but {
          width: 130px;
          height: 40px;
          font-size: 16px;
          color: #ffffff;
          opacity: 1;

          // background: #8bb9ff;
          .right {
            width: 120px;
            height: 45px;
            position: absolute;
            left: 106%;
            top: -4px;
            display: flex;
            padding-top: -100px;
            color: #000;
            .please {
              margin-top: 12px;
              margin-left: 10px;
              cursor: pointer;
            }
            & > img {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              object-fit: cover;
            }
            .header_name {
              //width: 100%;
              margin-left: 10px;
            }
            .el-icon--right {
              position: absolute;
              left: 50%;
              top: 187x;
            }
          }
          .search_but_text {
            margin-top: 8px;
            margin-left: 17px;
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
    }
  }
}
// 100%
@media screen and (min-width: 1555px) {
  .header {
    width: 100%;
    height: 80px;
    background: #ffff;
    .left {
      width: 450px;
      height: 52px;
      display: flex;
      // background: #8bb9ff;
      margin-left: 14%;
      //background: greenyellow;
      .logo {
        // width: 60px;
        height: 50px;
        margin-top: 15px;
      }
      .logo_title {
        width: auto;
        height: 100%;
        line-height: 52px;
        text-align: left;
        margin-top: 14px;
        font-size: 20px;
        font-weight: bold;
        margin-left: 3%;
      }
    }
    .middle {
      width: 750px;
      height: 35px;
      font-size: 16px;
      font-weight: 400;
      margin-left: 41%;
      margin-top: -30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      // background: #8bb9ff;
      .search {
        // width: 364px;
        width: 230px;
        height: 35px;
        display: flex;
        margin-left: 45px;
        // background: green;
        .search_menu {
          width: 235px; //128
          height: 20px;
          padding: auto;
          text-align: center;
        }
        .search_but {
          width: 130px;
          height: 40px;
          font-size: 16px;
          color: #ffffff;
          opacity: 1;

          //background: #8bb9ff;
          .right {
            // margin-left: -10px;
            width: 142px;
            height: 45px;
            position: absolute;
            left: 106%;
            top: -3px;
            display: flex;
            padding-top: -100px;
            color: #000;
            //background: greenyellow;
            .please {
              margin-top: 5px;
              margin-left: 10px;
              cursor: pointer;
            }
            & > img {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              object-fit: cover;
            }
            .header_name {
              //background: pink;
              //width: 100%;
              margin-left: 10px;
            }
            .el-icon--right {
              position: absolute;
              left: 50%;
              top: 187x;
            }
          }

          .search_but_text {
            margin-top: 8px;
            margin-left: 17px;
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.39);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1111;
  .register {
    max-width: 680px;
    max-height: 647px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 1% 8%;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 46px;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
    form {
      width: 350px;
      max-height: 500px;
      margin: 10% auto;
      label {
        display: block;
        width: 350px;
        height: 40px;
        background: #ffffff;
        border: 1px solid rgba(217, 217, 217, 0.65);
        border-radius: 20px;
        padding: 6px 5px;
        box-sizing: border-box;
        margin-bottom: 24px;
        background: url("../assets/images/sprite.png") no-repeat;
        position: relative;
        &::before {
          content: "*";
          color: red;
          position: absolute;
          top: 50%;
          left: -6%;
          transform: translateY(-50%);
        }
        input {
          width: 80%;
          height: 75%;
          border: 0;
          outline: 0;
          font-size: 16px;
          &::placeholder {
            color: rgba($color: #000000, $alpha: 0.3);
          }
        }

        b {
          font-weight: normal;
          color: #f56c6c;
          font-size: 12px;
          position: absolute;
          left: 0;
          bottom: -50%;
          opacity: 0;
          transition: all 0.5s;
        }
      }
      button.now-register {
        width: 239px;
        height: 40px;
        background: #8bb9fc;
        border-radius: 20px;
        font-size: 15px;
        color: #ffffff;
        border: 0;
        margin-top: 12px;
        cursor: pointer;
      }
      .label-name-icon {
        background-position: 4% -3%;
      }
      .label-unit-icon {
        background-position: 4% 18%;
      }
      .label-phone-icon {
        background-position: 4% 39%;
      }
      .label-code-icon {
        position: relative;
        background-position: 4% 61%;
        button.send-code-btn {
          height: 100%;
          border: 0;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 50px;
          color: #000;
          cursor: pointer;
        }
        .send-disable {
          cursor: not-allowed !important;

          background-color: #e7e8e9;
        }
      }
      .label-password-icon {
        background-position: 4% 82%;
      }
    }
  }
  .login-mask {
    width: 25%;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 50px;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      margin-bottom: 47px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .forget-mask {
    width: 25%;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 50px;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      margin-bottom: 47px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
    .msg-box {
      display: flex;
      button {
        width: 40%;
        border: 0;
        background-color: rgba($color: #8bb9fc, $alpha: 0.8);
        border-radius: 50px;
        cursor: pointer;
        color: #fff;

        &:hover {
          background-color: rgba($color: #8bb9fc, $alpha: 0.6);
        }
      }
    }
  }
}
.c-b {
  width: 0;
  height: 0;
  overflow: hidden;
  .certificate {
    width: 1080px;
    height: 1524px;
    background: url("../assets/images/cert.jpg") no-repeat;
    background-size: cover;
    position: relative;
    top: 100%;
    left: 0;
    // z-index: -99;
    span {
      font-size: 38px;
      // font-weight: 400;
      font-family: "楷体";
      position: absolute;
      color: #3a3a3a;
    }
    .cert-name {
      top: 37.1%;
      left: 19%;
    }
    .cert-stime {
      top: 37.1%;
      left: 42.5%;
      // font-size: 32px;
    }
    .cert-etime {
      top: 37.1%;
      left: 70%;
      // font-size: 32px;
    }
    .cert-course-time {
      top: 45.2%;
      right: 30%;
    }
    .cert-identifier {
      bottom: 22.3%;
      left: 30.5%;
      font-size: 26px;
    }
    .cert-ftime {
      left: 30.5%;
      bottom: 17.7%;
      font-size: 26px;
    }
    .cert-cname {
      right: 13%;
      bottom: 12%;
    }
  }
}
</style>
