<template>
  <div class="main">
   <Header/>
    <div class="main-box">
      <div class="title">
        <p>{{ NewListContent.title }}</p>
      </div>
      <div class="create-time">
        来源&nbsp;:&nbsp;<span>{{ NewListContent.source }}</span
        >&nbsp;&nbsp;&nbsp;&nbsp; 发布时间&nbsp;:&nbsp;<span
          class="el-icon-pie-chart"
        ></span
        ><span class="create-time-right">{{ create_time }}</span>
      </div>
      <!-- <div v-html="NewListContent.content" class="content"></div> -->
      <div v-if="NewListContent.type === 1" v-html="NewListContent.content" class="content"></div>
     <div v-else class="pdf-viewer">
    <iframe :src="NewListContent.content" width="100%" align="middle"></iframe>
    </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
export default {
  data() {
    return {
      NewListContent: [], //新闻详细信息
      create_time: [], //创建时间
    };
  },
  created() {
    this.getNewListContent();
  },
  methods: {
    getNewListContent() {
      this.axios
        .get(`/index/review_detail?id=${this.$route.query.id}`)
        .then((res) => {
          console.log(res.data);
          this.NewListContent = res.data;
          this.create_time = res.data.create_time.slice(0, 11);
        });
    },
  },
  components: { 
     Header,
  }
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  padding-top: 20px;
  padding-bottom: 30px;
  .main-box {
    width: 1000px;
    height: 800px;
    background: #fff;
    margin: 0 auto;
    overflow-y: scroll;
    .title {
      font-size: 22px;
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
      font-family: "微软雅黑";
    }
    .create-time {
      width: 70%;
      margin: 0 auto;
      border-bottom: 1px solid #9999;
      text-align: center;
      padding-bottom: 10px;
      font-size: 15px;
      .create-time-right {
        margin-top: -3px;
        margin-left: 5px;
      }
    }
    .content {
      margin-left: 30px;
      margin-right: 30px;
      font-size: 17px;
      font-family: "微软雅黑";
      line-height: 36px;
      text-indent: 2em;
    }
    .pdf-viewer {
        min-height: calc(100vh - 80px);
        overflow: hidden;
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 20px;
    iframe {
        border: 0;
        min-height: calc(100vh - 90px);
      }
    }
  }
}
</style>
